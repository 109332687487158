import React from "react";
import {
  SEO,
  PageLayout,
  DynamicTiles,
  MultipleComponentIterator,
  Section,
  MarkdownContent,
  Accordion,
  MultipleComponentIteratorMap,
  InternalLink,
  BasicCarousel,
  componentIterator,
  Image,
  FishermanIcon,
  FadeReveal,
  SlideReveal,
  BackgroundImageTeamMember,
  FullImageCard,
  BlogPostLink,
  FormattedDate,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  PaginatedGrid,
  SocialMediaV2,
} from "@bluefin/components";
import { Header, Grid, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withArgs,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceItemSlug,
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      dynamicTilesComponent,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessFile,
      allFishermanBusinessTeam,
      allFishermanBusinessBlogPost,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <DynamicTiles className={"custom-hero"}>
            <MultipleComponentIterator
              iterator={dynamicTilesComponent.childConfigurations}
              components={[
                {
                  component: <DynamicTiles.Tile />,
                  propMap: {
                    images: "files",
                    content: "contentNodes",
                    interactions: "interactions",
                    className: "data.className",
                    overlayClassName: "data.overlayClassName",
                    width: "data.width",
                  },
                },
              ]}
            />
          </DynamicTiles>
          <Section className={"services-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_header",
                defaultValue: "Discover Services",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_description",
              })}
            />
            <Accordion exclusive={true} className={"services-accordion"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={4}
                components={[
                  {
                    component: <React.Fragment />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <Accordion.Title
                            activeIcon={"minus"}
                            inactiveIcon={"plus"}
                          />
                        ),
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: <Header as={"h2"} />,
                            children: [
                              {
                                component: <span />,
                                propMap: { children: "name" },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: <Accordion.Content />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: (
                              <Grid
                                className={"main content"}
                                doubling={true}
                                stackable={true}
                                columns={2}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <Grid.Column
                                    className={"services"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: <Grid />,
                                    children: [
                                      {
                                        component: (
                                          <MultipleComponentIteratorMap
                                            lookupField={"_id"}
                                            limit={4}
                                            iterator={
                                              allFishermanBusinessMenuItem.nodes
                                            }
                                            components={[
                                              {
                                                component: (
                                                  <Grid.Column width={16} />
                                                ),
                                                children: [
                                                  {
                                                    component: (
                                                      <InternalLink
                                                        event={{
                                                          category: "Services",
                                                          action:
                                                            "View Item Details",
                                                        }}
                                                      />
                                                    ),
                                                    propMap: {
                                                      "event.label": "name",
                                                      to: withArgs({
                                                        func: createServiceItemSlug,
                                                        args: [
                                                          allFishermanBusinessMenuCategory.nodes,
                                                        ],
                                                      }),
                                                    },
                                                    children: [
                                                      {
                                                        component: (
                                                          <Header as={"h3"} />
                                                        ),
                                                        propMap: {
                                                          children: "name",
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ]}
                                          />
                                        ),
                                        propMap: { targetValues: "items" },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                component: (
                                  <Grid.Column
                                    className={"category-images"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: (
                                      <BasicCarousel
                                        size={1}
                                        displayIndicators={false}
                                        displayPlayPauseButton={false}
                                        autoScroll={false}
                                        leftArrowIcon={"fancy-arrow-left"}
                                        rightArrowIcon={"fancy-arrow-right"}
                                        animation={{
                                          name: "blur",
                                          duration: 200,
                                          transitionProps: {
                                            preAnimating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                            animating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                          },
                                        }}
                                      />
                                    ),
                                    propMap: {
                                      items: withNamedArgs({
                                        func: componentIterator,
                                        args: {
                                          iterator: withNamedArgs({
                                            func: getBusinessFiles,
                                            args: {
                                              businessFiles:
                                                allFishermanBusinessFile.nodes,
                                            },
                                          }),
                                          component: (
                                            <Image background={true} />
                                          ),
                                          propMap: { src: "__all__" },
                                        },
                                      }),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: <div className={"button-container"} />,
                            children: [
                              {
                                component: (
                                  <Button
                                    basic={true}
                                    event={{
                                      category: "Services",
                                      action: "View Category Details",
                                    }}
                                    as={InternalLink}
                                    icon={
                                      <FishermanIcon
                                        iconName={"fancy-arrow-right"}
                                      />
                                    }
                                    content={"See All"}
                                    labelPosition={"right"}
                                  />
                                ),
                                propMap: {
                                  "event.label": "name",
                                  to: createServiceCategorySlug,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Accordion>
            <div className={"see-all-offering-button-container"}>
              <Button
                basic={true}
                icon={true}
                event={{ category: "Services", action: "View Services" }}
                as={InternalLink}
                to={"/services/"}
              >
                <span>See Full Service Offering</span>
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={6} className={"markdown-column"}>
                <div>
                  <FadeReveal duration={1250} triggerOnce={true}>
                    <Header
                      as={"h2"}
                      className={"section-header"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "Our Story",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <div className={"ctas-container"} />
                  </FadeReveal>
                </div>
              </Grid.Column>
              <Grid.Column width={10} className={"images-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    leftArrowIcon={"fancy-arrow-left"}
                    rightArrowIcon={"fancy-arrow-right"}
                    slide={true}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_image_gallery",
                      }),
                      component: <Image background={true} />,
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "Featured Team Members",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"stylists"} itemsPerRow={3}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={3}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                            moreInfoIconName={"fancy-arrow-right"}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          "teamMember.social": "socialMedia",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-stylists-button-container"}>
              <Button
                basic={true}
                icon={true}
                event={{ category: "Team", action: "View All Team Members" }}
                as={InternalLink}
                to={"/team/"}
              >
                <span>See All Team Members</span>
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <div className={"component-section-container home-page ad-hoc-page"}>
            <Grid.Column width={16} textAlign={"center"}>
              <Image
                src={
                  "https://fisherman.gumlet.io/public/aa562aee-8e76-46b9-b717-92a30a4dd93f/DSCF1384.JPG"
                }
                className={"image hero image-overlay"}
                background={true}
              >
                <div className={"ad-hoc-content"}>
                  <Button
                    primary={true}
                    as={InternalLink}
                    to={"/payment-plans/"}
                  >
                    Checkout Payment Plans
                  </Button>
                </div>
              </Image>
            </Grid.Column>
          </div>
          <Section className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "Recent Articles",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group className={"blogs"} itemsPerRow={2} stackable={true}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <FullImageCard />,
                    propMap: { key: "_id", image: "gatsbyHeroImage" },
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug", title: "title" },
                        children: [
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <Card.Description className={"summary"} />
                            ),
                            propMap: { content: "summary" },
                          },
                          {
                            component: (
                              <FishermanIcon iconName={"fancy-arrow-right"} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-blogs-button-container"}>
              <Button
                to={"/blog/"}
                basic={true}
                as={InternalLink}
                event={{ category: "Blog", action: "View All Blog Posts" }}
                icon={true}
              >
                <span>Read All Articles</span>
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"trusted-by-section"}>
            <Grid stackable={true} columns={2}>
              <Grid.Column width={5} className={"copy-column"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "trusted_by_section_header",
                    defaultValue: "Trusted By:",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={11} className={"images-column"}>
                <BasicCarousel
                  className={"image-gallery"}
                  size={4}
                  mobile={{ displayAll: true, displayAllColumns: 1 }}
                  displayIndicators={false}
                  displayPlayPauseButton={false}
                  autoScroll={true}
                  leftArrowIcon={"fancy-arrow-left"}
                  rightArrowIcon={"fancy-arrow-right"}
                  animation={{
                    name: "blur",
                    duration: 200,
                    transitionProps: {
                      preAnimating: { opacity: 0.99, blur: "2px" },
                      animating: { opacity: 0.99, blur: "2px" },
                    },
                  }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                      componentIdentifier: "trusted_by_image_gallery",
                    }),
                    component: <Image background={true} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_header",
                defaultValue: "What Our Clients Say",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_description",
              })}
            />
            <BasicCarousel
              className={"reviews-carousel faded-sides-carousel"}
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              leftArrowIcon={"fancy-arrow-left"}
              rightArrowIcon={"fancy-arrow-right"}
              displayPlayPauseButton={false}
              slide={true}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <ReviewV2.Image />,
                        propMap: { src: "image" },
                        children: [
                          {
                            component: (
                              <FishermanIcon iconName={"quote-left"} />
                            ),
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
              triggerButtonProps={{
                basic: true,
                labelPosition: "right",
                icon: <FishermanIcon iconName={"fancy-arrow-right"} />,
              }}
            />
          </Section>
          <Section className={"image-gallery-section"}>
            <PaginatedGrid
              className={"image-gallery tight-overlay"}
              rows={3}
              columns={4}
              tablet={{ columns: 3, rows: 3 }}
              mobile={{ columns: 2, rows: 2, stackable: false }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: <Image background={true} />,
                propMap: { src: "__all__" },
              })}
            />
            <div className={"absolute-position-overlay"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "image_section_header",
                  defaultValue: "Connect With Us",
                })}
              />
              <SocialMediaV2>
                <MultipleComponentIterator
                  iterator={fishermanBusiness.socialMedia}
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
            </div>
          </Section>
          <Section className={"instagram-section"}>
            <div
              className={"elfsight-app-2a5890be-4fc7-4a62-ac2c-537dee116ddb"}
              data-elfsight-app-lazy={true}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    dynamicTilesComponent: fishermanWebsiteComponent(
      page: { pageType: { eq: "Home" } }
      fastId: { eq: "DynamicTiles" }
    ) {
      childConfigurations {
        data {
          className
          overlayClassName
          width
        }
        files {
          _id
          altText
          file
          order
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          _id
          content
          contentType
          contentName
          order
        }
        interactions {
          _id
          displayType
          behaviorType
          enabled
          label
          order
          style
          url
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        _id
        description
        name
        items
        files
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        summary
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
